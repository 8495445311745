.drag-drop-file-input {
  &__text-label {
    @extend .form-label;
  }

  &-drop-area {
    @extend .form-control;
    @extend .form-control-solid;

    height: 12rem;
    overflow-y: auto;
    margin: -0.2rem;

    &--empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &--has-value {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    }

    &-thumbnail {
      width: 7rem;
      height: 7rem;
      position: relative;
      margin: 0.2rem;
      padding: 0.2rem;
      border: 1px solid gray;

      &__close-button {
        position: absolute;
        top: -5px;
        right: -5px;
        width: 1.5rem !important;
        height: 1.5rem !important;
      }

      &__image {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
    &-page-thumbnail {
      width: 10rem;
      height: 10rem;
      position: relative;
      margin: 0.2rem;
      padding: 0.2rem;
      border: 1px solid gray;

      &__close-button {
        position: absolute;
        top: -5px;
        right: -5px;
        width: 1.5rem !important;
        height: 1.5rem !important;
      }

      &__image {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
    &-event-image {
      width: auto;
      height: 100px;
      position: relative;
      margin: 0.2rem;
      padding: 0.2rem;
      border: 1px solid gray;

      &__close-button {
        position: absolute;
        top: -5px;
        right: -5px;
        width: 1.5rem !important;
        height: 1.5rem !important;
      }

      &__image {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.modal {
  & .modal-wide {
    width: 90%;
    max-width: none;
    height: 100%;
    margin-top: 0;
    margin-bottom: 0;
    & .modal-content-big {
      height: 90%;
    }
  }
}

.swal2-popup {
  &.swal2-popup__big {
    width: 80vw;
    height: 80vh;
 
  }
  & .swal2-html-container {
    & .swal2-html-portal {
      display: contents;
    }
  }
  &.swal2-popup__autoHeight {
    width: 80vw;
    height: auto;
 
  }
}

.pdf-modal {
    height: 80vh !important;
}
